import { createApp } from 'vue'
import App from './App.vue'
import WeixinTips from 'COMPONENTS/WeixinTips'
import { Lazyload, Popup } from 'vant'
import 'STYLE/vant.reset.scss'
import { webpSupport } from 'UTILS/constants'
import './style/index.scss'
const app = createApp(App)
window.reloadH5Page = () => {
  // nothing
}
;(async function Initialize() {
  window.webpSupport = webpSupport
  app.config.globalProperties.webpSupport = webpSupport

  app.use(Lazyload).use(Popup)
  app.component('WeixinTips', WeixinTips)
  app.mount('#app')
})()
