import Main from './Main.vue'
import { App, createApp, createVNode, render, VNode } from 'vue'

const Tips = function (): VNode {
  const instance = createVNode(Main)
  const container = document.body
  render(instance, container)
  return instance
}
Tips.install = function (app: App): void {
  // 微信点击的提示
  Object.defineProperty(app.config.globalProperties, '$weixinTips', { value: Tips })
}
export default Tips

export function showWeChatTips() {
  const app = createApp(Main, {
    onClick: () => {
      app.unmount()
      div.remove()
    },
  })
  const div = document.createElement('div')
  document.body.appendChild(div)
  app.mount(div)
}
