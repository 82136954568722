const getEnv = () => {
  const localEnv = localStorage.getItem('env')
  if (localEnv) {
    return localEnv
  }
  if (location.href.indexOf('localhost') !== -1 || /\d+\.\d+\.\d+\.\d+/.test(location.href)) {
    return 'Debug'
  } else if (location.href.indexOf('alpha') > -1) {
    return 'Test'
  }
  return 'Product'
}

// 赛博云云接口地址
export const DomainMap = {
  Debug: '',
  Test: '',
  Product: 'https://e-api.cyber66.net/cgi-bin',
  development: 'https://e-api.cyber66.net/cgi-bin',
  pre: '',
  production: 'https://e-api.cyber66.net/cgi-bin',
}

const ua = window.navigator.userAgent
const uaLowerCase = ua.toLowerCase()

export const ENV = getEnv()

// ipad13.3系统用这个会有问题
// export const isIOS = !!(ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/))
export const isIOS = !!ua.match(/Mac OS X/)

export const isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1

export const isInApp = uaLowerCase.indexOf('Paikeyun') > -1 ;

export const isWechatOrQQOrWeibo = uaLowerCase.indexOf('micromessenger') >= 0 || uaLowerCase.indexOf(' qq') > -1 || uaLowerCase.indexOf('weibo') >= 0

export const webpSupport = !![].map && document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0

export const iosAppStore = 'https://apps.apple.com/cn/app/%E8%8F%9C%E6%9C%BA/id1486556047'

export const isCyber = /Paikeyun/i.test(uaLowerCase)
